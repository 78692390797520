// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-billboard-jsx": () => import("./../src/pages/billboard.jsx" /* webpackChunkName: "component---src-pages-billboard-jsx" */),
  "component---src-pages-directory-jsx": () => import("./../src/pages/directory.jsx" /* webpackChunkName: "component---src-pages-directory-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-metrics-jsx": () => import("./../src/pages/metrics.jsx" /* webpackChunkName: "component---src-pages-metrics-jsx" */)
}

